<template>
     <div class="d-block d-lg-none">
        <div class="row">
            <div class="col-8 col-lg-10">
                <p class="messagingMobile" style="margin-left: 20px;">Message settings</p>
            </div>
            <div class="col-4 col-lg-2">
                <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
            </div>
        </div>
        <div class="hr-border"></div>
    </div>
    <div class="mobileStylePublic">
      <div class="row settingStyle">
        <div class="col-12">
            <label>Posts</label>
        </div>
        <div class="col-12 form-group">
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="post1" id="post1" value="post1">
                <label class="form-check-label" for="post1">Publish my posts to all communities and groups that I am part of</label>
            </div>
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="post2" id="post2" value="post2">
                <label class="form-check-label" for="post2">Allow RxIx members to comment on my posts</label>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <label>Questions</label>
        </div>
        <div class="col-12 form-group">
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="mn1" id="mn1" value="mn1">
                <label class="form-check-label" for="mn1">Publish my questions to all communities and groups that I am part of</label>
            </div>
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="mn2" id="mn2" value="mn2">
                <label class="form-check-label" for="mn2">Allow public who have not signed up with RxIx to view my questions</label>
            </div>
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="mn3" id="mn3" value="mn3">
                <label class="form-check-label" for="mn3">Allow RxIx members to reply to my questions</label>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <label>Messages and notifications</label>
        </div>
        <div class="col-12 form-group">
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="mq1" id="mq1" value="mq1">
                <label class="form-check-label" for="mq1">Allow members to send me private messages</label>
            </div>
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="mq2" id="mq2" value="mq2">
                <label class="form-check-label" for="mq1">Send notifications to my primary mail ID and mobile number</label>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    data() {
    return {}},
    methods: {
    GoSetting(){
            this.$router.push({ path:`/user/mobile-setting` });
            
        },
    }

}
</script>
<style>
@media screen and (max-width:991px) {
    .settingStyle{
        margin-top: 15px;
    }
    .mobileStylePublic{
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom:6rem;
    }
    .messagingMobile {
        margin-left: 20px;
        /* margin-bottom: 10px; */
        margin-top: 10px;
        color: #000;
        font-weight: 550;
    }
}
@media screen and (max-width:576px) {
}
    </style>
<!-- <script lang="js" src='./settings.js'></script> -->
<template>
  <div class="d-block d-lg-none">
    <div class="row">
      <div class=" col-10 mb-0 col-sm-9 col-lg-10">
        <p class="topicsMobile" style="margin-left: 20px;">Topics of interest</p>
      </div>
      <div class="col-2 col-sm-3 col-lg-2">
        <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
    </div>
    <div class="hr-border"></div>
  </div>
  <div class="mobileStyleDoctor">
    <div class="topictitile">
      <p class="subHeadingText mb-0">Select the speciality that interests you.</p>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-4" v-for="(disorders, i) in interest" :key="i">
        <label class="checkbox-inline">
          <input type="checkbox" :checked="disorders.isActive" v-model="disorders.isActive" 
            class="me-2">{{ disorders.disorder }}
        </label>
      </div>

    </div>
    <div class="row mt-3">
      <div class="col-12">
        <button class="btn btn-blue-color text-white" @click="updateDisorder()">
          Save
        </button>
      </div>
    </div>

  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      interest: [],
      // selected: [],
      // isDisabled: false,
      // indeterminate: false,
      joinpublicData: [],
      userPayload: {},
      getDisTopicData:[],
      topicData:[]
    }
  },
  created: async function () {
    let sample = {
      "disorder": "General Information",
      "disorder_id": "24",
      "id": 24,
    }
    this.selectedDisorder = sample
   await this.getPracticeArea();
    this.getDisTopic();
    this.showTopicDisorder();
  },
  methods: {
    GoSetting() {
      window.scrollTo(0, 0);
      this.$router.push(`/user/mobile-setting`);
    },

    getDisTopic() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/public-user/${localStorage.getItem('publicSignUpData')}/one`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
                    this.getDisTopicData = response.data;
          this.disorderData = response.data.getDisordersId;

          const patientDisorder = response.data.publicPatientId;
          patientDisorder.forEach(topic => {
              const patientTopics = topic.specialities;
              this.topicData = this.topicData.concat(patientTopics);
          });
      
          this.interest.forEach(item => {
    const getdisorder = this.disorderData.some(library => library.id === item.id);
    const getPatinetDisorder = this.topicData.some(patientGroup => patientGroup.id === item.id);
    item.isActive = !!getdisorder || !!getPatinetDisorder;
});
        })
    },
    getPracticeArea() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    return axios
        .get(`${BASE_API_URL}/library-disorders`)
        .then(async (response) => {
          if (response?.data) {
            this.interest = response.data;
            this.interest.map((ids)=>{
              this.disorderIds = ids
            })
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
   
    updateDisorder() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      const selectedDisorder = [];
            this.interest.forEach(item => {
                if (item.isActive) {
                  selectedDisorder.push(item);
                }
            });
           const userPayload={
            getDisordersId :selectedDisorder
           }
      axios
        .put(`${BASE_API_URL}/public-user/${localStorage.getItem('publicSignUpData')}/update`, userPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.$swal('Updated successfully!!')
            window.scrollTo(0, 0);
            this.$router.push('/user/library')
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
    showTopicDisorder() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/patient-support/add`)
        .then((response) => {
          if (response?.data) {
            this.supportData = response.data;
            this.supportData?.map((data) => {
              this.special = data.specialities;
            })
          }
        })
        .catch(function (error) {
          console.log(error.response);
        })
    }
  }
}
</script>
<style>
.subHeadingText{
  font-size: 23px;
  }
@media screen and (max-width:991px) {
  .subHeadingText{
  font-size: 20px;
  }
  .mobileStyleDoctor {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 6rem;
  }
  .topicsMobile {
    margin-left: 20px;
    /* margin-bottom: 10px; */
    margin-top: 1rem;
    color: #000;
    font-weight: 550;
    font-size: 21px;
  }

  .topictitile {
    margin-top: 15px;
  }

}
</style>
